import "./login-page.scss";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useNavigate } from "react-router-dom";
import Logo from "../assets/images/logo.png";
import axios from "axios";
import { useUserContext } from "../context/dashboard-context";
import { Form, Button, InputGroup, FormControl, FormGroup, FormCheck } from 'react-bootstrap';
import { useState } from "react";


const LoginPage = () => {
  const navigate = useNavigate();
  const [password1, setPassword1] = useState();
  const [username1, setusername1] = useState();
  const [sms, setSms] = useState("")
  console.log(sms, 'sms');

  const { setToken, password, setPassword, username, setUsername, token } =
    useUserContext();
  console.log(password, "password");
  function LoginHandler(e) {
    e.preventDefault();
    axios
      .post("https://api.protool.uz/v1/users/login", {
        email: username ? username : username1,
        password: password ? password : password1,
      })
      .then(function (response) {
        setToken(response.data?.data);
        console.log(response.status.message, 'status');
        if (response?.status === 400) {
          setSms("Login yoki parol xato")
        }
        if (response?.status === 200) {
          navigate("/admin/news");
        }
      })
      .catch(function (error) {
        console.log(error.code, 'error');
        if (error.code === "ERR_BAD_REQUEST") {
          setSms("Login yoki parol xato")
        }
      });
  }
  console.log(sms, 'sms');

  return (
    <>
      <div className="container">
        <div className="login">
          <div className="login-input">
            <div className="logo">
              <Link to="/">
                <img src={Logo} alt="logo" />
              </Link>
            </div>
            <div className="login-title">
              <h1>Admin dashboardga kirish</h1>
              <p>Iltimos, login va parolni kiriting</p>
            </div>
            <div className="login-input-body">
              <Form onSubmit={LoginHandler}>
                <Form.Group className="my-3" controlId="formEmail">
                  <Form.Label>Email</Form.Label>
                  <FormControl
                    type="email"
                    placeholder="Enter email"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                  />
                </Form.Group>

                <Form.Group className="my-3" controlId="formPassword">
                  <Form.Label>Password</Form.Label>
                  <FormControl
                    type="password"
                    placeholder="Enter password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </Form.Group>

                <h1 style={{ textAlign: 'center', color: 'red', margin: '12px 0',fontSize:"24px" }}>{sms}</h1>

                {/* <FormGroup controlId="formCheckbox">
                  <FormCheck type="checkbox" label="Eslab qolish" />
                  <Link to="/">Parolni unutdingizmi?</Link>
                </FormGroup> */}

                <button  type="submit" className="login-btn my-5">
                  Kirish
                </button>
              </Form>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default LoginPage;
