const NewsIcon = () => {
  return (
    <>
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_285_1803)">
          <path
            d="M9.33341 2.35352V5.10721C9.33341 5.48058 9.33341 5.66726 9.40608 5.80987C9.46999 5.93531 9.57198 6.0373 9.69742 6.10121C9.84003 6.17388 10.0267 6.17388 10.4001 6.17388H13.1538M9.33341 12.1738H5.33341M10.6667 9.50716H5.33341M13.3334 7.49931V12.3072C13.3334 13.4273 13.3334 13.9873 13.1154 14.4151C12.9237 14.7915 12.6177 15.0974 12.2414 15.2892C11.8136 15.5072 11.2535 15.5072 10.1334 15.5072H5.86675C4.74664 15.5072 4.18659 15.5072 3.75877 15.2892C3.38244 15.0974 3.07648 14.7915 2.88473 14.4151C2.66675 13.9873 2.66675 13.4273 2.66675 12.3072V5.37383C2.66675 4.25372 2.66675 3.69367 2.88473 3.26585C3.07648 2.88952 3.38244 2.58356 3.75877 2.39182C4.18659 2.17383 4.74664 2.17383 5.86675 2.17383H8.00793C8.49711 2.17383 8.7417 2.17383 8.97188 2.22909C9.17595 2.27808 9.37104 2.35889 9.54998 2.46855C9.75182 2.59223 9.92477 2.76518 10.2707 3.11109L12.3962 5.23657C12.7421 5.58247 12.915 5.75543 13.0387 5.95726C13.1484 6.1362 13.2292 6.33129 13.2782 6.53536C13.3334 6.76554 13.3334 7.01013 13.3334 7.49931Z"
            stroke="#929EAE"
            stroke-width="1.3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_285_1803">
            <rect
              width="16"
              height="16"
              fill="#929EAE"
              transform="translate(0 0.840454)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};
const ProductsIcon = () => {
  return (
    <>
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.6666 6.17379V11.6405C13.6666 12.7606 13.6666 13.3206 13.4486 13.7484C13.2569 14.1248 12.9509 14.4307 12.5746 14.6225C12.1467 14.8405 11.5867 14.8405 10.4666 14.8405H5.53325C4.41315 14.8405 3.85309 14.8405 3.42527 14.6225C3.04895 14.4307 2.74299 14.1248 2.55124 13.7484C2.33325 13.3206 2.33325 12.7606 2.33325 11.6405V6.17379M2.39992 2.84045H13.5999C13.9733 2.84045 14.16 2.84045 14.3026 2.91312C14.428 2.97703 14.53 3.07902 14.5939 3.20446C14.6666 3.34707 14.6666 3.53375 14.6666 3.90712V5.10712C14.6666 5.48049 14.6666 5.66717 14.5939 5.80978C14.53 5.93522 14.428 6.03721 14.3026 6.10113C14.16 6.17379 13.9733 6.17379 13.5999 6.17379H2.39992C2.02655 6.17379 1.83987 6.17379 1.69726 6.10113C1.57182 6.03721 1.46983 5.93522 1.40591 5.80978C1.33325 5.66717 1.33325 5.48049 1.33325 5.10712V3.90712C1.33325 3.53375 1.33325 3.34707 1.40591 3.20446C1.46983 3.07902 1.57182 2.97703 1.69726 2.91312C1.83987 2.84045 2.02655 2.84045 2.39992 2.84045ZM6.39992 8.50712H9.59992C9.97329 8.50712 10.16 8.50712 10.3026 8.57978C10.428 8.6437 10.53 8.74569 10.5939 8.87113C10.6666 9.01373 10.6666 9.20042 10.6666 9.57379V10.1071C10.6666 10.4805 10.6666 10.6672 10.5939 10.8098C10.53 10.9352 10.428 11.0372 10.3026 11.1011C10.16 11.1738 9.97329 11.1738 9.59992 11.1738H6.39992C6.02655 11.1738 5.83987 11.1738 5.69726 11.1011C5.57182 11.0372 5.46983 10.9352 5.40591 10.8098C5.33325 10.6672 5.33325 10.4805 5.33325 10.1071V9.57379C5.33325 9.20042 5.33325 9.01373 5.40591 8.87113C5.46983 8.74569 5.57182 8.6437 5.69726 8.57978C5.83987 8.50712 6.02655 8.50712 6.39992 8.50712Z"
          stroke="#929EAE"
          stroke-width="1.3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};
const CategoryIcon = () => {
  return (
    <>
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14 8.8405L6 8.84049M14 4.8405L6 4.84049M14 12.8405L6 12.8405M3.33333 8.84049C3.33333 9.20868 3.03486 9.50716 2.66667 9.50716C2.29848 9.50716 2 9.20868 2 8.84049C2 8.47231 2.29848 8.17383 2.66667 8.17383C3.03486 8.17383 3.33333 8.47231 3.33333 8.84049ZM3.33333 4.84049C3.33333 5.20868 3.03486 5.50716 2.66667 5.50716C2.29848 5.50716 2 5.20868 2 4.84049C2 4.4723 2.29848 4.17383 2.66667 4.17383C3.03486 4.17383 3.33333 4.4723 3.33333 4.84049ZM3.33333 12.8405C3.33333 13.2087 3.03486 13.5072 2.66667 13.5072C2.29848 13.5072 2 13.2087 2 12.8405C2 12.4723 2.29848 12.1738 2.66667 12.1738C3.03486 12.1738 3.33333 12.4723 3.33333 12.8405Z"
          stroke="#929EAE"
          stroke-width="1.3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};
const Star = () => {
  return (
    <>
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.52169 3.14262C7.67534 2.83133 7.75217 2.67569 7.85647 2.62596C7.94722 2.58269 8.05264 2.58269 8.14339 2.62596C8.24769 2.67569 8.32452 2.83133 8.47817 3.14262L9.93596 6.09594C9.98132 6.18784 10.004 6.23379 10.0372 6.26946C10.0665 6.30105 10.1017 6.32665 10.1408 6.34483C10.185 6.36536 10.2357 6.37277 10.3371 6.3876L13.5979 6.86422C13.9413 6.91441 14.113 6.9395 14.1924 7.02337C14.2616 7.09634 14.2941 7.19661 14.2809 7.29626C14.2658 7.4108 14.1415 7.53187 13.8929 7.774L11.5342 10.0714C11.4607 10.143 11.4239 10.1788 11.4002 10.2214C11.3792 10.2591 11.3657 10.3006 11.3605 10.3434C11.3547 10.3918 11.3633 10.4424 11.3807 10.5436L11.9372 13.7885C11.9959 14.1307 12.0253 14.3019 11.9701 14.4034C11.9221 14.4918 11.8368 14.5537 11.738 14.5721C11.6243 14.5931 11.4707 14.5123 11.1633 14.3507L8.24817 12.8176C8.15734 12.7699 8.11193 12.746 8.06408 12.7366C8.02172 12.7283 7.97814 12.7283 7.93578 12.7366C7.88793 12.746 7.84252 12.7699 7.75169 12.8176L4.83653 14.3507C4.5292 14.5123 4.37553 14.5931 4.2619 14.5721C4.16304 14.5537 4.07773 14.4918 4.02974 14.4034C3.97459 14.3019 4.00394 14.1307 4.06264 13.7885L4.61918 10.5436C4.63653 10.4424 4.6452 10.3918 4.63933 10.3434C4.63413 10.3006 4.62066 10.2591 4.59966 10.2214C4.57593 10.1788 4.53917 10.143 4.46564 10.0714L2.10696 7.774C1.85836 7.53187 1.73406 7.4108 1.71894 7.29626C1.70578 7.19661 1.73829 7.09634 1.80742 7.02337C1.88688 6.9395 2.05857 6.91441 2.40195 6.86422L5.66279 6.3876C5.7642 6.37277 5.81491 6.36536 5.85906 6.34483C5.89816 6.32665 5.93336 6.30105 5.96271 6.26946C5.99586 6.23379 6.01854 6.18784 6.0639 6.09594L7.52169 3.14262Z"
          stroke="#929EAE"
          stroke-width="1.3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};
const Logout = () => {
  return (
    <>
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.6667 12.1738L14 8.84045M14 8.84045L10.6667 5.50712M14 8.84045H6M6 2.84045H5.2C4.0799 2.84045 3.51984 2.84045 3.09202 3.05844C2.7157 3.25019 2.40973 3.55615 2.21799 3.93247C2 4.3603 2 4.92035 2 6.04045V11.6405C2 12.7606 2 13.3206 2.21799 13.7484C2.40973 14.1248 2.71569 14.4307 3.09202 14.6225C3.51984 14.8405 4.0799 14.8405 5.2 14.8405H6"
          stroke="#929EAE"
          stroke-width="1.3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};
const User = () => {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.3333 17.5V15.8333C18.3333 14.2801 17.271 12.9751 15.8333 12.605M12.9166 2.7423C14.1382 3.23679 15 4.43443 15 5.83333C15 7.23224 14.1382 8.42988 12.9166 8.92437M14.1666 17.5C14.1666 15.9469 14.1666 15.1703 13.9129 14.5577C13.5746 13.741 12.9257 13.092 12.1089 12.7537C11.4963 12.5 10.7198 12.5 9.16663 12.5H6.66663C5.11349 12.5 4.33692 12.5 3.72435 12.7537C2.90759 13.092 2.25867 13.741 1.92036 14.5577C1.66663 15.1703 1.66663 15.9469 1.66663 17.5M11.25 5.83333C11.25 7.67428 9.75758 9.16667 7.91663 9.16667C6.07568 9.16667 4.58329 7.67428 4.58329 5.83333C4.58329 3.99238 6.07568 2.5 7.91663 2.5C9.75758 2.5 11.25 3.99238 11.25 5.83333Z"
          stroke="#5D82DB"
          stroke-width="1.8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};
const Arrow = () => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 17L17 7M17 7H7M17 7V17"
          stroke="#101828"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};

const Pen = () => {
  return (
    <>
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_104_5760)">
          <path
            d="M10.5001 5.83331L8.16672 3.49997M1.45837 12.5416L3.43259 12.3223C3.67379 12.2955 3.79439 12.2821 3.90712 12.2456C4.00713 12.2132 4.1023 12.1675 4.19006 12.1096C4.28897 12.0444 4.37478 11.9586 4.54638 11.787L12.2501 4.08331C12.8944 3.43898 12.8944 2.39431 12.2501 1.74997C11.6057 1.10564 10.5611 1.10564 9.91672 1.74997L2.21305 9.45363C2.04144 9.62524 1.95564 9.71104 1.89041 9.80996C1.83254 9.89771 1.7868 9.99289 1.75442 10.0929C1.71793 10.2056 1.70453 10.3262 1.67773 10.5674L1.45837 12.5416Z"
            stroke="black"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_104_5760">
            <rect width="14" height="14" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

const EyeIcon = () => {
  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M8.137 15.147c-.71-.857-1.146-1.947-1.146-3.147 0-2.76 2.241-5 5-5 1.201 0 2.291.435 3.148 1.145l1.897-1.897c-1.441-.738-3.122-1.248-5.035-1.248-6.115 0-10.025 5.355-10.842 6.584.529.834 2.379 3.527 5.113 5.428l1.865-1.865zm6.294-6.294c-.673-.53-1.515-.853-2.44-.853-2.207 0-4 1.792-4 4 0 .923.324 1.765.854 2.439l5.586-5.586zm7.56-6.146l-19.292 19.293-.708-.707 3.548-3.548c-2.298-1.612-4.234-3.885-5.548-6.169 2.418-4.103 6.943-7.576 12.01-7.576 2.065 0 4.021.566 5.782 1.501l3.501-3.501.707.707zm-2.465 3.879l-.734.734c2.236 1.619 3.628 3.604 4.061 4.274-.739 1.303-4.546 7.406-10.852 7.406-1.425 0-2.749-.368-3.951-.938l-.748.748c1.475.742 3.057 1.19 4.699 1.19 5.274 0 9.758-4.006 11.999-8.436-1.087-1.891-2.63-3.637-4.474-4.978zm-3.535 5.414c0-.554-.113-1.082-.317-1.562l.734-.734c.361.69.583 1.464.583 2.296 0 2.759-2.24 5-5 5-.832 0-1.604-.223-2.295-.583l.734-.735c.48.204 1.007.318 1.561.318 2.208 0 4-1.792 4-4z" /></svg>
}
const DeleteIcon = () => {
  return (
    <>
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.33333 3.49996V3.03329C9.33333 2.3799 9.33333 2.0532 9.20617 1.80364C9.09432 1.58411 8.91584 1.40564 8.69632 1.29379C8.44676 1.16663 8.12006 1.16663 7.46667 1.16663H6.53333C5.87994 1.16663 5.55324 1.16663 5.30368 1.29379C5.08416 1.40564 4.90568 1.58411 4.79383 1.80364C4.66667 2.0532 4.66667 2.3799 4.66667 3.03329V3.49996M5.83333 6.70829V9.62496M8.16667 6.70829V9.62496M1.75 3.49996H12.25M11.0833 3.49996V10.0333C11.0833 11.0134 11.0833 11.5034 10.8926 11.8778C10.7248 12.2071 10.4571 12.4748 10.1278 12.6426C9.75347 12.8333 9.26342 12.8333 8.28333 12.8333H5.71667C4.73657 12.8333 4.24653 12.8333 3.87218 12.6426C3.5429 12.4748 3.27518 12.2071 3.10741 11.8778C2.91667 11.5034 2.91667 11.0134 2.91667 10.0333V3.49996"
          stroke="#E80001"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};
export { NewsIcon, ProductsIcon, CategoryIcon, Star, Logout, User, Arrow, Pen, DeleteIcon, EyeIcon };
