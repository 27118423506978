import React, { useState } from "react";
import { Modal, Input, message } from "antd";
import { Pen, DeleteIcon } from "../../assets/images/icons";
import "./bannercard.scss";
import axios from "axios";
import { useUserContext } from "../../context/dashboard-context";
import ImageUploader from "../uploader";

const Bannercard = ({ link, img, title, description, id, type, name }) => {
  const { token, GetBanners } = useUserContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [updatedLink, setUpdatedLink] = useState(link);
  const [updatedImage, setUpdatedImage] = useState(img);
  const [imageFile, setImageFile] = useState(null);
  const [updatedTitle, setUpdatedTitle] = useState(title);
  const [updatedDescription, setUpdatedDescription] = useState(description);
  const [value, setValue] = useState(name);

  const { images } = useUserContext();
  const showModal = () => {
    setIsModalOpen(true);
  };

  const showEditForm = () => {
    setIsEditMode(true);
    showModal();
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setIsEditMode(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsEditMode(false);
  };

  const removeBanner = () => {
    axios
      .delete(`https://api.protool.uz/v1/${type}/${id}`, {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        GetBanners();
        handleOk();
        message.success("Banner o`chirildi");
      })
      .catch((err) => {
        console.error(err);
        message.error("Bannerni oʻchirib boʻlmadi");
      });
  };
  const updateProduct = () => {
    const formData = new FormData();
    if (imageFile) {
      formData.append("file", imageFile);

      axios
        .post("https://api.protool.uz/v1/file-upload", formData, {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          const imageUrl = response.data.data.url || updatedImage;

          const updateEndpoint = imageUrl
            ? `https://api.protool.uz/v1/${type}/${id}`
            : `https://api.protool.uz/v1/file-upload`;

          axios
            .put(
              updateEndpoint,
              {
                name: value,
                title: updatedTitle,
                description: updatedDescription,
                imageUrl: imageUrl,
                link: updatedLink,
              },
              {
                headers: {
                  Authorization: "Bearer " + token,
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            )
            .then(() => {
              GetBanners();
              handleOk();
              message.success("Banner yangilandi");
            })
            .catch((err) => {
              console.error(err);
              if (err.response && err.response.status === 401) {
                localStorage.removeItem("token");
              }
              message.error("Mahsulot yangilanmadi");
            });
        })
        .catch((error) => {
          console.error(error);
          message.error("Image upload failed");
        });
    } else {
      // If no new image, update only other details without uploading a new image
      axios
        .put(
          `https://api.protool.uz/v1/${type}/${id}`,
          {
            name: value,
            title: updatedTitle,
            description: updatedDescription,
            imageUrl: img,
            link: updatedLink,
          },
          {
            headers: {
              Authorization: "Bearer " + token,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {
          GetBanners();
          handleOk();
          message.success("Banner yangilandi");
        })
        .catch((err) => {
          console.error(err);
          if (err.response && err.response.status === 401) {
            localStorage.removeItem("token");
          }
          message.error("Mahsulot yangilanmadi");
        });
    }
  };
  const updateBanner = () => {
    const formData = new FormData();
    formData.append("file", imageFile);

    axios
      .post("https://api.protool.uz/v1/file-upload", formData, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        const imageUrl = response.data.data.url || updatedImage;
        axios
          .put(
            `https://api.protool.uz/v1/${type}/${id}`,
            {
              imageUrl: imageUrl,
              link: updatedLink,
            },
            {
              headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          )
          .then(() => {
            GetBanners();
            handleOk();
            message.success("Banner yangilandi");

            setUpdatedTitle("");
            setUpdatedDescription("");
            setUpdatedLink("");
            setUpdatedImage(null);
            setImageFile(null);
          })
          .catch((err) => {
            console.error(err);
            if (err.response && err.response.status === 401) {
              localStorage.removeItem("token");
            }
            message.error("Bannerni yangilab bo‘lmadi");
          });
      })
      .catch((error) => {
        message.error("Bannerni yangilab bo‘lmadi");
      });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setUpdatedImage(URL.createObjectURL(file));
    setImageFile(file);
  };

  return (
    <>
      <div className="bannercard">
        <div className="card-img">
          <img src={img} alt="" />
          <div className="card-edit">
            <button onClick={showEditForm} className="card_edit">
              <Pen />
              O’zgartirish
            </button>
            <button onClick={removeBanner} className="card_delete">
              <DeleteIcon />
              O’chirish
            </button>
          </div>
        </div>
        <div className="card-text">
          <h1>{}</h1>
          <p>{}</p>
        </div>
      </div>
      <Modal
        title={isEditMode ? "Update Banner" : "Delete Banner"}
        visible={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        {isEditMode ? (
          <div className="bannercard_modal">
            <p className="bannercard_modal_info">Bannerni o’zgartirish</p>
            <label
              className="product_edit_card_input_label"
              htmlFor="img"
              style={{
                backgroundImage: `url(${updatedImage ? updatedImage : img})`,
              }}
            >
              <input
                onChange={handleImageChange}
                type="file"
                name="img"
                id="img"
                className="product_edit_card_input"
              />
              <div className="upload-icon">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21 15V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V15M17 8L12 3M12 3L7 8M12 3V15"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <p>Boshqa rasm yuklash</p>
              </div>
            </label>
            <label htmlFor="title" className="bannercard_modal_title">
              Name
              <Input
                id="name"
                name="name"
                placeholder="Name"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                style={{ marginBottom: "16px" }}
                required
              />
            </label>
            <label htmlFor="title" className="bannercard_modal_title">
              Mavzu
              <Input
                id="title"
                name="title"
                placeholder="Title"
                value={updatedTitle}
                onChange={(e) => setUpdatedTitle(e.target.value)}
                style={{ marginBottom: "16px" }}
                required
              />
            </label>
            <label htmlFor="link" className="bannercard_modal_title">
              Link
              <Input
                id="link"
                name="link"
                placeholder="Link"
                value={updatedLink}
                onChange={(e) => setUpdatedLink(e.target.value)}
                style={{ marginBottom: "16px" }}
                required
              />
            </label>
            <label htmlFor="description" className="bannercard_modal_title">
              Matn
              <Input.TextArea
                id="description"
                name="description"
                placeholder="Description"
                value={updatedDescription}
                onChange={(e) => setUpdatedDescription(e.target.value)}
                style={{ marginBottom: "16px", resize: "none" }}
                required
              />
            </label>
            <div className="modal-footer">
              <button onClick={handleCancel}>
                <span>Bekor qilish</span>
              </button>
              <button className="bannercard_modal_save" onClick={updateProduct}>
                <span>Saqlash</span>
              </button>
            </div>
          </div>
        ) : (
          <div>
            <p>Are you sure you want to delete this banner?</p>
            <div className="modal-footer">
              <button onClick={handleCancel} className="bannercard_modal_edit">
                <span>Bekor qilish</span>
              </button>
              <button
                className="bannercard_modal_delete"
                onClick={removeBanner}
              >
                <span>o`chirish</span>
              </button>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default Bannercard;
