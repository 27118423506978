import cardImg from "../assets/images/google(2).png";
import cardImgSecond from "../assets/images/pinterest2.png";
import cardImgThird from "../assets/images/spotify2.png";
// import NewsCard from "../components/news-card";
import "./news-page.scss";
import { useEffect, useState } from "react";
import { Form, Input, Modal, Pagination } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import ProductCard from "../components/product-card";
import axios from "axios";
import { useUserContext } from "../context/dashboard-context";
import ImageUploader from "../components/uploader";
import BrandCard from "../components/product-card/brand-card";

const BrendsPage = () => {
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };
  const {
    images,
    token,
    brands,
    setBrands,
    GetBrands,
    currentPage,
    totalProducts,
    setCurrentPage,
  } = useUserContext();
  console.log(totalProducts, "totalProducts brand");
  const handlePageChange = (page) => {
    console.log(page, "page");
    setCurrentPage(page);
    GetBrands(page);
  };

  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };
  const [value, setValue] = useState();
  const [img, setImg] = useState(null);
  const AddCategory = async () => {
    try {
      const response = await axios.post(
        "https://api.protool.uz/v1/brands",
        {
          name: value,
          image: images[0],
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      console.log(response);
      if (response.status === 200 || response.status === 201) {
        window.location.reload();
      }
      // setCategory();
    } catch (error) {
      console.error(error);

      if (
        error.response &&
        error.response.data &&
        error.response.data.code === "CATEGORY_NOT_FOUND"
      ) {
        console.log("Category not found");
      } else {
        console.log(error.response?.data.data);
      }
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const data = [
    {
      img: cardImgThird,
      title: "Akfa",
      description: false,
    },
    {
      img: cardImg,
      title: "Google",
      description: false,
    },
    {
      img: cardImgSecond,
      title: "Pinterest",
      description: false,
    },
    {
      img: cardImgThird,
      title: "Spotify",
      description: false,
    },
  ];
  const [form] = Form.useForm();

  return (
    <>
      <div className="news-page">
        <div className="page-top">
          <h1>Brendlar</h1>
          <button onClick={showModal}>+ Brend qo’shish</button>
        </div>
        <div className="page-cards">
          {brands?.map((i, k) => (
            <BrandCard data={i} />
          ))}
        </div>
        <Pagination
          current={currentPage}
          defaultCurrent={1}
          total={totalProducts}
          onChange={handlePageChange}
        />
      </div>
      <div className="modal-news">
        <Modal
          title="Brend qo’shish"
          open={isModalOpen}
          // onOk={handleOk}
          onCancel={handleCancel}
        >
          <div className="modal-body">
            <div className="body-img">
              <Form
                layout="vertical"
                form={form}
                onFinish={() => {
                  AddCategory();
                  handleOk();
                }}
                action=""
              >
                <ImageUploader />
                {/* <label htmlFor="title">Mavzu</label>
                <input
                  type="text"
                  name=""
                  onChange={(e) => setValue(e.target.value)}
                  id="title"
                  placeholder="Mavzu nomi"
                  required
                /> */}

                <Form.Item
                  label="Mavzu"
                  name="Mavzu"
                  rules={[{ required: true, message: "Mavzu is required" }]}
                >
                  <Input
                    placeholder="Mavzu"
                    onChange={(e) => setValue(e.target.value)}
                  />
                </Form.Item>
                <div className="modal-footer">
                  <button onClick={handleCancel}>
                    <span>Bekor qilish</span>
                  </button>
                  <button>
                    <span>Qo'shish</span>
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};
export default BrendsPage;
