import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo-grey.png";
import {
  CategoryIcon,
  Logout,
  NewsIcon,
  ProductsIcon,
  Star,
} from "../../assets/images/icons";
import "./sidebar.scss";

const Sidebar = () => {
  const currentPath = window.location.pathname;
  console.log(currentPath, "pathname");
  const navigate = useNavigate();
  const handleHardRefresh = () => {
    // Append a random query parameter to the URL
    const randomQueryParam = `?random=${Math.random()}`;
    window.location.href = window.location.href + randomQueryParam;
  };

  return (
    <div className="sidebar">
      <div>
        <div className="sidebar-top">
          <div className="sidebar-logo">
            <Link onClick={() => navigate('/admin/news')} to={"/admin/news"}>
              <img src={Logo} alt="" />
            </Link>
          </div>
          <div className="menu">
            <button onClick={() => {
              navigate("/admin/news");
              handleHardRefresh()
            }}
              className={currentPath === "/admin/news" ? 'link active' : 'link'}>
              <NewsIcon />
              Yangiliklar
            </button>
            <button onClick={() => {
              navigate("/admin/products");
              handleHardRefresh()
            }}
              className={currentPath === "/admin/products" ? "link active " : "link"}
            >
              <ProductsIcon />
              Mahsulotlar
            </button>
            <button
              onClick={() => {
                navigate("/admin/category");
                handleHardRefresh()
              }}
              className={currentPath === "/admin/category" ? "link active " : "link"}>
              <CategoryIcon />
              Kategoriyalar
            </button>

            <button
              onClick={() => {
                navigate("/admin/brends");
                handleHardRefresh()
              }}
              className={currentPath === "/admin/brends" ? "link active " : "link"}>
              <Star />
              Brendlar</button>
            <button
              onClick={() => {
                navigate("/admin/banners");
                handleHardRefresh()
              }}
              className={currentPath === "/admin/banners" ? "link active " : "link"}>
              <Star />
              Bannerlar</button>
          </div>
        </div>
        <div className="sidebar-bottom">
          <Link onClick={()=>localStorage.removeItem("token")} to={"/"}>
            <Logout />
            Logout
          </Link>
        </div>
      </div>
    </div>
  );
};
export default Sidebar;
