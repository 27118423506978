import User from "../../assets/images/users-01.png";
import "./card-counter.scss";
const CounterCard = ({ title, count }) => {
  return (
    <>
      <div className="counter-card">
        <div className="counter-card-left">
          <img src={User} alt="" />
        </div>
        <div className="card-right">
          <p>{title}</p>
          <h1>{count}</h1>
        </div>
      </div>
    </>
  );
};
export default CounterCard;
