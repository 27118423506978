import { useState } from "react";
import { useUserContext } from "../../context/dashboard-context";
import "./main.scss";
import { Spin } from "antd";
const ImageUploader = ({clear}) => {
  const { images, setImages, token } = useUserContext();
  const [loading, setLoading] = useState(false)
  const handleImageChange = async (e) => {
    setLoading(true);
    try {
      let imageFiles = e.target.files;
      let restImages = [...images];
      const uploadPromises = [];

      for (let file of imageFiles) {
        restImages.push(file);
        const formData = new FormData();
        formData.append("file", file);

        const uploadPromise = fetch("https://api.protool.uz/v1/file-upload", {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
          },
          body: formData,
        })
          .then((response) => response.json())
          .then((data) => data?.data?.url)
          .catch((error) => {
            console.error("Error uploading image:", error);
            return null;
          });

        uploadPromises.push(uploadPromise);
      }

      const uploadedUrls = await Promise.all(uploadPromises);

      setImages((prevImages) => [
        ...prevImages,
        ...uploadedUrls.filter((url) => url !== null),
      ]);
    } catch (error) {
      console.error("Error handling image change:", error);
    } finally {
      setLoading(false); // Set loading to false regardless of success or failure
    }
  };


  return (
    <div>
      {loading && <h1>Loading....</h1>}
      <label
        className="product_edit_card_input_label"
        htmlFor="img"
      // style={{ backgroundImage: `url(${images[0]})` }}
      >
        <input
          required
          onChange={handleImageChange}
          type="file"
          name="img"
          id="img"
          className="product_edit_card_input"
        />
        <div className="upload-icon">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21 15V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V15M17 8L12 3M12 3L7 8M12 3V15"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p>
            Boshqa rasm yuklash
          </p>
        </div>
      </label>
      <div
        className={
          images.length > 1 ? `images-product` : `images-product first`
        }
      >
        {!clear && images.map((image, index) => (
          <div key={index}>

              <img
                style={{
                  width: "100%",
                  height: "300px",
                  objectFit: "cover"
                }}
                src={image}
                alt={`Preview ${index}`}
              />
          </div>
        ))}

      </div>
    </div>
  );
};

export default ImageUploader;
