import { useEffect, useState } from "react";
import { Button, Form, Input, Modal, Pagination } from "antd";
import { message } from "antd";
import axios from "axios";
import { useUserContext } from "../context/dashboard-context";
import SearchInput from "../components/search";
import Categorycard from '../components/categorycard/Categorycard';
import "./news-page.scss";
import ImageUploader from "../components/uploader";

const CategoriesPage = () => {
  const [form] = Form.useForm();
  const { token, categorys, GetCategory, setCategory, images, currentPage,
    totalProducts,
    setCurrentPage } = useUserContext();
  useEffect(() => {
    GetCategory();
  }, []);
  const handlePageChange = (page) => {
    console.log(page, 'page');
    setCurrentPage(page);
    GetCategory(page);
  };

  const [value, setValue] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [img, setImg] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setValue("");
    setImageUrl(null);
    setImg(null);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };



  const AddCategory = async () => {
    try {
      const response = await axios.post(
        "https://api.protool.uz/v1/categories",
        {
          title: value,
          image: images[0],
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      console.log(response);
      if (response.status === 200 || response.status === 201) {
        console.log("succes");
        form.resetFields();
        GetCategory()
      }

    } catch (error) {
      console.error(error);

      if (
        error.response &&
        error.response.data &&
        error.response.data.code === "CATEGORY_NOT_FOUND"
      ) {
        console.log("Category not found");
      } else {
        console.log(error.response?.data.data);
      }
    }
  };




  // useEffect(() => {
  //   if (img) {
  //     AddCategory();
  //   }
  // }, [img]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImageUrl(file);
    setImg(URL.createObjectURL(file));
  };

  return (
    <>
      <div className="news-page">
        <div className="page-top">
          <h1>Kategoriyalar</h1>
          <button onClick={showModal}>+ Kategoriya qo’shish</button>
        </div>
        <SearchInput endp={"categories"} />
        <div className="page-cards">
          {categorys?.map((i, k) => (
            <Categorycard
              type={"categories"}
              link={`/admin/category/`}
              id={i._id}
              img={i.image}
              title={i.title}
            />
          ))}
        </div>
        <Pagination
          current={currentPage}
          defaultCurrent={1}
          total={totalProducts}
          onChange={handlePageChange}
        />
      </div>
      <div className="modal-news">
        <Modal
          title="Kategoriya qo’shish"
          open={isModalOpen}
          onCancel={handleCancel}
        >
          {/* <div className="modal-body">
            <div className="body-img">
              <Form onSubmit={() => {
                AddCategory()
                handleOk();
              }} action="">
                <ImageUploader />

                <label htmlFor="name">
                  Nomi
                  <input
                    required
                    id='name'
                    name='name'
                    onChange={(e) => setValue(e.target.value)}
                    type="text"
                    placeholder="nomi"
                  />
                </label>
              </Form>


            </div>
          </div>
          <div className="modal-footer">
            <button onClick={handleCancel}>
              <span>Bekor qilish</span>
            </button>
            <button
              type="submit"
            >
              <span>Qo'shish</span>
            </button>
          </div> */}


          <Form form={form} onFinish={(e) => {
            AddCategory()
            handleOk();
            e.preventDefault()
          }} layout="vertical">
            <div className="body-img">
              <ImageUploader />
            </div>

            <Form.Item
              label="Nomi"
              name="name"
              rules={[
                { required: true, message: 'Majburiy !' },
              ]}
            >
              <Input onChange={(e) => setValue(e.target.value)} placeholder="nomi" />
            </Form.Item>

            <div className="modal-footer">
              <Button onClick={handleCancel}>
                <span>Bekor qilish</span>
              </Button>
              <Button type="primary" htmlType="submit">
                <span>Qo'shish</span>
              </Button>
            </div>
          </Form>

        </Modal>
      </div>
    </>
  );
};
export default CategoriesPage;
