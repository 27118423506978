import React, { useState, useEffect } from "react";
import { Modal, Input, message, Select, Switch } from "antd";
import { Link } from "react-router-dom";
import { DeleteIcon, Pen } from "../../assets/images/icons";
import "./product-card.scss";
import { useUserContext } from "../../context/dashboard-context";
import axios from "axios";

const { Option } = Select;

const ProductCard = ({ img, description, title, id, excerpt, type, price }) => {
  console.log(title);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [updatedTitle, setUpdatedTitle] = useState(title);
  
  const [updatedDescription, setUpdatedDescription] = useState(description);
  const [updatedPrice, setUpdatedPrice] = useState(0);
  const [updatedImage, setUpdatedImage] = useState(img);
  const [categoryId, setCategoryId] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [updatedExcerpt, setUpdatedExcerpt] = useState(excerpt);
  const {
    token,
    GetCategory,
    GetProducts,
    categorys,
    images,
    GetNews,
    GetBrands,
  } = useUserContext();

  useEffect(() => {
    if (isEditMode && categorys && categorys.length > 0) {
      setCategoryId(categorys[0]._id);
    }
  }, [isEditMode, categorys]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const showEditForm = () => {
    setIsEditMode(true);
    showModal();
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setIsEditMode(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsEditMode(false);
  };

  const removeProduct = () => {
    axios
      .delete(`https://api.protool.uz/v1/${type}/${id}`, {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        GetCategory();
        GetProducts();
        handleOk();
        GetBrands();
        message.success("Mahsulot o`chirildi");
      })
      .catch((err) => {
        console.error(err);
        if (err.response && err.response.status === 401) {
          localStorage.removeItem("token");
        }
        message.error("Mahsulot o`chirilmadi");
      });
  };

  const updateProduct = () => {
    const formData = new FormData();
    if (imageFile) {
      formData.append("file", imageFile);

      axios
        .post("https://api.protool.uz/v1/file-upload", formData, {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          const imageUrl = response.data.data.url || updatedImage;

          const updateEndpoint = imageUrl
            ? `https://api.protool.uz/v1/${type}/${id}`
            : `https://api.protool.uz/v1/file-upload`;

          axios
            .put(
              updateEndpoint,
              {
                title: updatedTitle ? updatedTitle : title,
                ...(type === "brands"
                  ? { name: updatedTitle ? updatedTitle : title }
                  : {}),
                description: updatedDescription
                  ? updatedDescription
                  : description,
                price: updatedPrice ? parseFloat(updatedPrice) : price,
                category: categoryId,
                images: imageUrl ? [imageUrl] : img,
              },
              {
                headers: {
                  Authorization: "Bearer " + token,
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            )
            .then(() => {
              GetNews();
              GetCategory();
              GetProducts();
              GetBrands();
              handleOk();
              message.success("Mahsulot yangilandi");
            })
            .catch((err) => {
              console.error(err);
              if (err.response && err.response.status === 401) {
                localStorage.removeItem("token");
              }
              message.error("Mahsulot yangilanmadi");
            });
        })
        .catch((error) => {
          console.error(error);
          message.error("Image upload failed");
        });
    } else {
      // If no new image, update only other details without uploading a new image
      axios
        .put(
          `https://api.protool.uz/v1/${type}/${id}`,
          {
            ...(type === "brands"
              ? { name: updatedTitle ? updatedTitle : title }
              : {}),
            title: updatedTitle ? updatedTitle : title,
            description: updatedDescription ? updatedDescription : description,
            price: updatedPrice ? parseFloat(updatedPrice) : price,
            category: categoryId,
            images: [img], // Keep the existing images
            excerpt: updatedExcerpt,
          },
          {
            headers: {
              Authorization: "Bearer " + token,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {
          GetCategory();
          GetProducts();
          GetNews();
          handleOk();

          message.success("Mahsulot yangilandi");

          setUpdatedTitle("");
          setUpdatedDescription("");
          setUpdatedPrice(0);
          setUpdatedImage(null);
          setImageFile(null);
        })
        .catch((err) => {
          console.error(err);
          if (err.response && err.response.status === 401) {
            localStorage.removeItem("token");
          }
          message.error("Mahsulot yangilanmadi");
        });
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setUpdatedImage(URL.createObjectURL(file));
    setImageFile(file);
  };

  return (
    <>
      <div className="product-card">
        <div className="card-img">
          <img src={img} alt="" />
          <div className="card-edit">
            <button onClick={showEditForm}>
              <Pen />
              O’zgartirish
            </button>
            <button onClick={removeProduct}>
              <DeleteIcon />
              O’chirish
            </button>
          </div>
        </div>
        <Link to="/admin" className="card-text">
          <h1>{title}</h1>
          <p>{description}</p>
          <p>{new Intl.NumberFormat().format(price)} so'm</p>
        </Link>
      </div>
      <Modal
        title={isEditMode ? "Update Product" : "Delete Product"}
        visible={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        {isEditMode ? (
          <div className="product_edit_card">
            <p className="product_edit_card_info">
              {(() => {
                switch (type) {
                  case "products":
                    return "Mahsulotlarni";
                  case "brands":
                    return "Brandni";
                  case "categories":
                    return "Kategoriyani";
                  case "articles":
                    return "Yangilikni";
                  default:
                    return "o'zgartirish";
                }
              })()}{" "}
              o'zgartirish
            </p>

            {type === "brands" ? (
              <>
                <label
                  className="product_edit_card_input_label"
                  htmlFor="img"
                  style={{
                    backgroundImage: `url(${
                      updatedImage ? updatedImage : img
                    })`,
                  }}
                >
                  <input
                    onChange={handleImageChange}
                    type="file"
                    name="img"
                    id="img"
                    className="product_edit_card_input"
                  />
                  <div className="upload-icon">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21 15V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V15M17 8L12 3M12 3L7 8M12 3V15"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <p>Boshqa rasm yuklash</p>
                  </div>
                </label>
                <label htmlFor="title" className="product_edit_card_title">
                  Brand Nomi
                  <Input
                    id="title"
                    name="title"
                    placeholder="Brand Name"
                    value={updatedTitle}
                    onChange={(e) => setUpdatedTitle(e.target.value)}
                    style={{ marginBottom: "16px" }}
                    required
                  />
                </label>
              </>
            ) : (
              <>
                <label
                  className="product_edit_card_input_label"
                  htmlFor="img"
                  style={{
                    backgroundImage: `url(${
                      updatedImage ? updatedImage : img
                    })`,
                  }}
                >
                  <input
                    onChange={handleImageChange}
                    type="file"
                    name="img"
                    id="img"
                    className="product_edit_card_input"
                  />
                  <div className="upload-icon">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21 15V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V15M17 8L12 3M12 3L7 8M12 3V15"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <p>Boshqa rasm yuklash</p>
                  </div>
                </label>
                <div className="my-3 d-flex justify-content-between">
                  <label>
                    {" "}
                    Top {type === "products" ? "mahsulot" : "Category"}
                  </label>
                  <Switch style={{}} defaultChecked />
                </div>
                <label htmlFor="title" className="product_edit_card_title">
                  Mavzu
                  <Input
                    id="title"
                    name="title"
                    placeholder="Title"
                    value={updatedTitle}
                    onChange={(e) => setUpdatedTitle(e.target.value)}
                    style={{ marginBottom: "16px" }}
                    required
                  />
                </label>
                <label htmlFor="title" className="product_edit_card_title">
                  Excerpt
                  <Input
                    id="excerpt"
                    name="excerpt"
                    placeholder="excerpt"
                    value={updatedExcerpt}
                    onChange={(e) => setUpdatedExcerpt(e.target.value)}
                    style={{ marginBottom: "16px" }}
                    required
                  />
                </label>
                {type === "products" && (
                  <label htmlFor="price" className="product_edit_card_title">
                    Narxi
                    <Input
                      id="price"
                      name="price"
                      placeholder="Price"
                      type="text"
                      value={updatedPrice ? updatedPrice : price}
                      onChange={(e) => setUpdatedPrice(e.target.value)}
                      style={{ marginBottom: "16px" }}
                      required
                    />
                  </label>
                )}

                {type === "products" && (
                  <div>
                    <select
                      required
                      id="category"
                      value={categoryId}
                      onChange={(e) => setCategoryId(e.target.value)}
                      style={{
                        marginBottom: "16px",
                        width: "100%",
                        padding: "12px 8px",
                        borderRadius: "8px",
                      }}
                    >
                      {categorys?.map((cat) => (
                        <option key={cat._id} value={cat._id}>
                          {cat.title}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                <label
                  htmlFor="description"
                  className="product_edit_card_title"
                >
                  Matn
                  <Input.TextArea
                    id="description"
                    name="description"
                    placeholder="Description"
                    value={updatedDescription}
                    onChange={(e) => setUpdatedDescription(e.target.value)}
                    style={{ marginBottom: "16px", resize: "none" }}
                    required
                  />
                </label>
              </>
            )}
            <div className="modal-footer">
              <button onClick={handleCancel}>
                <span>Bekor qilish</span>
              </button>
              <button
                className="product_edit_card_save"
                onClick={updateProduct}
              >
                <span>Saqlash</span>
              </button>
            </div>
          </div>
        ) : (
          <div>
            <p>Siz rostdan ham ushbu mahsulotni o’chirmoqchimisiz?</p>
            <div className="modal-footer">
              <button onClick={handleCancel}>
                <span>Bekor qilish</span>
              </button>
              <button className="delete-btn" onClick={removeProduct}>
                <span>O'chirish</span>
              </button>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default ProductCard;
