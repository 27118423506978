import { useEffect, useState } from "react";
import { Button, Form, Input, Modal, Pagination } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { message } from "antd";
import { useUserContext } from "../context/dashboard-context";
import axios from "axios";
import ImageUploader from "../components/uploader";
import SearchInput from "../components/search";
import "./news-page.scss";
import ProductCard from "../components/product-card";
import NewsCard from "../components/product-card/news-card";

const NewsPage = () => {
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    news,
    GetNews,
    token,
    images,
    currentPage,
    totalProducts,
    setCurrentPage,
    refresh, setRefresh
  } = useUserContext();
  const handlePageChange = (page) => {
    console.log(page, "page");
    setCurrentPage(page);
    GetNews(page);
  };
  const [img, setImg] = useState();
  const [value, setValue] = useState();

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    GetNews();
  }, []);

  const [form] = Form.useForm();
  console.log(news, "news");

  const submitHandler = () => {
    const formData = new FormData();
    formData.append("file", imageUrl);

    fetch("https://api.protool.uz/v1/file-upload", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        setImg([...data?.data?.url]);
        console.log(data, "datas");
        if (data.message === "FILE_UPLOADED" && img) {
          onFinish();
        }
      })
      .catch((error) => {
        console.error("Error uploading image:", error);
      });
  };

  const [title, setTitle] = useState();
  const [body, setBody] = useState();
  const [author, setAuthor] = useState();
  const [excerpt, setExcerpt] = useState();


  const refreshPage = () => {
    setRefresh((value) => !value);
  };
  const onFinish = () => {
    const requestData = {
      title: title,
      body: body,
      author: author,
      excerpt: excerpt,
      cardImage: images[0],
    };

    axios
      .post("https://api.protool.uz/v1/articles", requestData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response.data);
        GetNews();
        setTitle("");
        setImg("");
        setBody("");
        setAuthor("");
        setExcerpt("");
        // window.location.reload();
        if (response.status === "201") {
          refreshPage()
        }

        handleOk();
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const AddCategory = () => {
    return axios
      .post(
        "https://api.protool.uz/v1/categories",
        {
          title: value,
          image: img,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        console.log(response.data);
        GetNews();

        setTitle("");
        setImg("");
        setBody("");
        setAuthor("");
        setExcerpt("");

        handleOk();
      })
      .catch((error) => {
        console.error(error);
        if (error.response && error.response.status === 401) {
          localStorage.removeItem("token");
        }
      });
  };

  return (
    <>
      <div className="news-page">
        <div className="page-top">
          <h1>Yangiliklar</h1>
          <button onClick={showModal}>+ Yangilik qo’shish</button>
        </div>
        <SearchInput endp={"articles"} />
        <div className="page-cards">
          {news?.map((i, k) => (
            <NewsCard data={i} />
          ))}
        </div>
        <Pagination
          current={currentPage}
          defaultCurrent={1}
          total={totalProducts}
          onChange={handlePageChange}
        />
      </div>
      <div className="modal-news">
        <Modal
          title="Mahsulot qo’shish"
          open={isModalOpen}
          onCancel={handleCancel}
          footer={[
            <Button key="cancel" onClick={handleCancel}>
              Bekor qilish
            </Button>,
            <Button key="submit" type="primary" onClick={onFinish}>
              Qo'shish
            </Button>,
          ]}
        >
          {/* <div className="modal-body">
            <div className="body-img">
              <form layout="horizontal" onSubmit={onFinish}>
                <div>
                  <ImageUploader />
                </div>
                <label htmlFor='title'>
                  Mavzu
                  <input
                    id='title'
                    name='title'
                    onChange={(e) => setTitle(e.target.value)}
                    type="text"
                    placeholder="Mavzu"
                  />
                </label>
                <label htmlFor='description'>
                  Matn
                  <input
                    id='description'
                    name='description'
                    onChange={(e) => setBody(e.target.value)}
                    type="text"
                    placeholder="Description"
                  />
                </label>
                <label htmlFor='author'>
                  Mualif
                  <input
                    id='author'
                    name='author'
                    onChange={(e) => setAuthor(e.target.value)}
                    type="text"
                    placeholder="author"
                  />
                </label>
                <label htmlFor='excerpt'>
                  Link
                  <input
                    className="w-100"
                    id='excerpt'
                    name='excerpt'
                    onChange={(e) => setExcerpt(e.target.value)}
                    type="text"
                    placeholder="excerpt"
                  />
                </label>
              </form>
            </div>
          </div>
   */}

          <Form
            form={form}
            layout="vertical"
            onFinish={() => {
              onFinish();
              handleOk();
            }}
          >
            <div className="body-img">
              <ImageUploader />
            </div>

            <Form.Item
              label="Mavzu"
              name="title"
              rules={[{ required: true, message: "Mavzu is required" }]}
            >
              <Input
                placeholder="Mavzu"
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Item>

            <Form.Item
              label="Matn"
              name="description"
              rules={[{ required: true, message: "Description is required" }]}
            >
              <Input
                placeholder="Description"
                onChange={(e) => setBody(e.target.value)}
              />
            </Form.Item>

            <Form.Item
              label="Mualif"
              name="author"
              rules={[{ required: true, message: "Author is required" }]}
            >
              <Input
                placeholder="author"
                onChange={(e) => setAuthor(e.target.value)}
              />
            </Form.Item>

            <Form.Item
              label="Link"
              name="excerpt"
              rules={[{ required: true, message: "Excerpt is required" }]}
            >
              <Input
                className="w-100"
                placeholder="excerpt"
                onChange={(e) => setExcerpt(e.target.value)}
              />
            </Form.Item>

            <div className="modal-footer">
              <button onClick={handleCancel}>
                <span>Bekor qilish</span>
              </button>
              <button>
                <span>Qo'shish</span>
              </button>
            </div>
          </Form>
        </Modal>
      </div>
    </>
  );
};
export default NewsPage;



// Kevin do'kondan qilgan xaridlariga etibor bering, ichida tayd kir yuvish parashogi bor, yo'lda paket yirtilib ketadi, va ichida parashok yoq

// Kevin idishni olayotgan paytida yenglari shimarilgan, keyingi kadrd esa yenglari tushirilgan, undan keyingi kadrda esa yana shimarilgan
//qizib turgan eshik tutqichini ushlaganiga etibor bering, aslida M harfi shu korinishda bolishi kerak, lekin M harfi umuman boshqacha ko'rinishda turibti


// dazmon tepadan tushganda, u peshonasiga tushgan, lekin kadrda butun yuzida dazmoldan qolgan iz bor