import "./news-page.scss";
import { useEffect, useState } from "react";
import { Form, Input, Modal, Pagination, Select } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import ProductCard from "../components/product-card";
import { useUserContext } from "../context/dashboard-context";
import axios from "axios";
import ImageUploader from "../components/uploader";
import SearchInput from "../components/search";

const ProductsPage = () => {
  const [form] = Form.useForm();
  const {
    GetProducts,
    images,
    products,
    token,
    categorys,
    GetCategory,
    currentPage,
    totalProducts,
    itemsPerPage,
    pagesCount,
    setCurrentPage

  } = useUserContext();
  const [img, setImg] = useState([]);
  const [title, setTitle] = useState('');
  const [desc, setDec] = useState('');
  const [excerpt, setExcerpt] = useState('');
  const [price, setPrice] = useState('');
  const [categoryId, setCategory] = useState();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  console.log(categoryId, 'categoryId');
  let restImages = [...images];


  const formatPrice = (inputPrice) => {
    return inputPrice.toString().replace(/\B(?=(\d{4})+(?!\d))/g, " ");
  };

  const handlePageChange = (page) => {
    console.log(page, 'page');
    setCurrentPage(page);
    // Fetch products for the new page
    GetProducts(page);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    // window.location.reload()
  };

  // const submitHandler = (e) => {
  //   const formData = new FormData();
  //   formData.append("file", imageUrl);

  //   axios.post("https://api.protool.uz/v1/file-upload", {
  //     headers: {
  //       Authorization: "Bearer " + token,
  //     },
  //     body: formData,
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setImg([...data?.data?.url]);
  //       if (data.message === "FILE_UPLOADED" && img) {
  //         onFinish();
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error uploading image:", error);
  //     });
  // };

  const onFinish = () => {
    const requestData = {
      title: title,
      images: images,
      description: desc,
      excerpt: excerpt,
      price: parseInt(price),
      category: categoryId,
    };

    axios
      .post("https://api.protool.uz/v1/products", requestData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("Product added successfully:", response.data);
        GetProducts();
        // window.location.reload()
        form.resetFields();
        setLoading(true)
      })
      .catch((error) => {
        console.error("Error adding product:", error);
      });
  };

 
  useEffect(() => {
    GetProducts();
    GetCategory();
  }, []);

  useEffect(() => {
    if (img) {
      onFinish();
    }
  }, [img]);
  console.log(products, 'title');

  return (
    <>
      <div className="news-page">
        <div className="page-top">
          <h1>Mahsulotlar</h1>
          <button onClick={showModal}>+ Mahsulot qo’shish</button>
        </div>
        <SearchInput endp={"products"} />
        <div className="page-cards">
          {products?.map((i, k) => (
            <ProductCard
              id={i?._id}
              type={"products"}
              img={i?.images[0]}
              title={i.title}
              excerpt={i?.excerpt}
              description={i.description}
              price={i?.price}
            />
          ))}
        </div>
        <Pagination
          current={currentPage}
          // pageSize={itemsPerPage}
          defaultCurrent={1}
          total={totalProducts}
          onChange={handlePageChange}
        />
        {/* <Pagination>
        {[...Array(Math.ceil(totalProducts / itemsPerPage))].map((_, index) => (
          <Pagination.Item
            key={index + 1}
            active={index + 1 === currentPage}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </Pagination.Item>
        ))}
      </Pagination> */}
      </div>
      <div className="modal-news">
        <Modal
          title="Mahsulot qo’shish"
          open={isModalOpen}
          onCancel={handleCancel}
        >
          <div className="modal-body">
            <div className="body-img">
              <Form form={form} layout="vertical" onFinish={() => {
                onFinish();
                handleOk();
              }}>
                <div>
                  <ImageUploader clear={loading}/>
                </div>

                <Form.Item
                  label="Title"
                  name="title"
                  rules={[{ required: true, message: 'Title is required' }]}
                >
                  <Input placeholder="Title" onChange={(e) => setTitle(e.target.value)} />
                </Form.Item>

                <Form.Item
                  label="Description"
                  name="description"
                  rules={[{ required: true, message: 'Description is required' }]}
                >
                  <Input placeholder="Description" onChange={(e) => setDec(e.target.value)} />
                </Form.Item>

                <Form.Item
                  label="Narxi"
                  name="price"
                  rules={[{ required: true, message: 'Price is required' }]}
                >
                  <Input
                    type="text"
                    placeholder="Narxi"
                    value={formatPrice(price)}
                    onChange={(e) => setPrice(e.target.value)}
                  />
                </Form.Item>

                <Form.Item
                  label="Excerpt"
                  name="excerpt"
                  rules={[{ required: true, message: 'Excerpt is required' }]}
                >
                  <Input placeholder="Excerpt" onChange={(e) => setExcerpt(e.target.value)} />
                </Form.Item>

                <Form.Item
                  label="Category"
                  name="category"
                  rules={[{ required: true, message: 'Category is required' }]}
                >
                  <Select placeholder="Kategoriya tanlash" onChange={(value) => setCategory(value)}>
                    {categorys?.map((i, k) => (
                      <Select.Option key={i._id} value={i._id}>
                        {i.title}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <div className="modal-footer">
                  <button type="button" onClick={handleCancel}>
                    <span>Bekor qilish</span>
                  </button>
                  <button>
                    <span>Qo'shish</span>
                  </button>
                </div>
              </Form>

            </div>
          </div>

        </Modal>
      </div>
    </>
  );
};
export default ProductsPage;
