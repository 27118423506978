import axios from "axios";
import { useEffect, useState } from "react";
import { useUserContext } from "../../context/dashboard-context";
import { Input } from "antd";

const SearchInput =({endp})=>{
    const[search,setSearch]=useState()
    const{setNews,setProducts,setCategory,GetCategory,GetNews,currentPage,
        totalProducts,
        setCurrentPage}=useUserContext()

    const GetSearch = (value) => {
        console.log(value,'value');
        axios.get(`https://api.protool.uz/v1/${endp}?search=${value}`).then((response) => {
          // setTotal(JSON.parse(response.headers["x-pagination"]));
          setSearch(response?.data?.data);
        });
      };
      console.log(endp,'endp');
      useEffect(()=>{
        if(endp === "products"){
            setProducts(search);
        }
        if(endp == "articles"){
            setNews(search);
            // GetNews()
        }
        if(endp === "categories"){
            setCategory(search);
        }
      },[endp,search])
      console.log(search);
    return(
        <>
        <form style={{
            margin:"25px 0"
        }} action="">
        <Input onChange={(e)=>GetSearch(e.target.value)} placeholder="Qidirish" />
            {/* <input onChange={(e)=>GetSearch(e.target.value)} type="search" /> */}
        </form>
        </>
    )
}

export default SearchInput