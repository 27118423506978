import { useUserContext } from "../../context/dashboard-context";
import CounterCard from "../card-counter";
import "./admin-about.scss";

const AdminAbout = () => {
  const{category,news,products}=useUserContext()
  const data = [
    {
    //   icon: { Users },
      title: "Barcha mijozlar",
      count: "0",
    },
    {
    //   icon: <User />,
      title: "Faol foydalanuvchilar",
      count: "0",
    },
    {
    //   icon: <User />,
      title: "Barcha Yangiliklar",
      count: news?.length,
    },
    {
    //   icon: <User />,
      title: "Barcha mahsulotlar",
      count: products?.length,
    },
  ];
  return (
    <>
      <div className="admin-about">
        <h1>Admin </h1>
        <div className="about-cards">
          {data?.map((i, k) => (
            <CounterCard icon={i.icon} title={i.title} count={i.count} />
          ))}
        </div>
      </div>
    </>
  );
};
export default AdminAbout;
